const config = {
     //apiUrl: "http://localhost:2999",
     //webUrl: "http://localhost:3000",
    apiUrl: "https://assureddisposal.earthideal.com:2997",
    webUrl: "https://assureddisposal.earthideal.com",
    // stripe_key: "pk_test_PYc3xQs1oc1pODsNJGmhTkLX00gnKwQncv",  //test key
    stripe_key: "pk_live_o2riQdZlnOHZHwNDUqlOypmF007FS0Aklq",  //assured key
    adFrontPage: "https://assureddisposal.earthideal.com",
    hcFrontPage: "https://haulcheck.earthideal.com",
    eiFrontPage: "https://app.earthideal.com",
    eiBackend: "https://app.earthideal.com:2999"
}
export default config;
